import { translation } from './../constants/OdPremiumData';
import { parse } from 'querystring';
import pathRegexp from 'path-to-regexp';
import { Route } from '@/models/connect';
import { ConnectState } from '@/models/connect';
import _, { isEmpty, trim } from 'lodash';
import { router } from 'umi';
import moment from 'moment';
import { isSupportedCountry, parsePhoneNumber } from 'react-phone-number-input';

/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

export const isAntDesignPro = (): boolean => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }
  return window.location.hostname === 'preview.pro.ant.design';
};

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export const isAntDesignProOrDev = (): boolean => {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'development') {
    return true;
  }
  return isAntDesignPro();
};

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

/**
 * props.route.routes
 * @param router [{}]
 * @param pathname string
 */
export const getAuthorityFromRouter = <T extends { path?: string }>(
  router: T[] = [],
  pathname: string,
): T | undefined => {
  const authority = router.find(({ path }) => path && pathRegexp(path).exec(pathname));
  if (authority) return authority;
  return undefined;
};

export const getRouteAuthority = (path: string, routeData: Route[]) => {
  let authorities: string[] | string | undefined;
  routeData.forEach(route => {
    // match prefix
    if (pathRegexp(`${route.path}/(.*)`).test(`${path}/`)) {
      if (route.authority) {
        authorities = route.authority;
      }
      // exact match
      if (route.path === path) {
        authorities = route.authority || authorities;
      }
      // get children authority recursively
      if (route.routes) {
        authorities = getRouteAuthority(path, route.routes) || authorities;
      }
    }
  });
  return authorities;
};

export const getMapStateToProps = (args: string[]) => {
  const mapStateToProps = (state: ConnectState) => {
    /**
     * Translation function
     * @param label
     * @returns {*}
     */
    const tx = (label: string) => {
      let ret = label;
      const [group, key] = label.split('.');
      if (state.meta.translations.translations && state.meta.translations.translations[group]) {
        if (state.meta.translations.translations[group][key]) {
          ret = state.meta.translations.translations[group][key];
        }
      }
      return ret;
    };
    /**
     * Translation function for od part
     * @param label
     * @returns {*}
     */
    const txo = (label: string) => {
      let ret = label;
      const [group, key] = label.split('.');
      if (state.od.translations.translations && state.od.translations.translations[group]) {
        if (state.od.translations.translations[group][key]) {
          ret = state.od.translations.translations[group][key];
        }
      }
      return ret;
    };

    let props = { helper: { tx, txo } } as any;

    args.map((item: string) => {
      if (state[item]) {
        props[item] = state[item];
      }
    });

    return props;
  };
  return mapStateToProps;
};

export const convertJsonToCamelCase = (data: any) => {
  const toCamel = (x: string) => _.camelCase(x);

  const convert = (item: any) => {
    let newData = item instanceof Array ? [] : {};
    _.forEach(item, (value, key) => {
      if (value instanceof Array) {
        newData[toCamel(key)] = [];
        _.forEach(value, v => {
          if (v instanceof Object) {
            newData[toCamel(key)].push(convert(v));
          } else {
            newData[toCamel(key)].push(v);
          }
        });
      } else if (value instanceof Object) {
        newData[toCamel(key)] = convert(value);
      } else {
        newData[toCamel(key)] = value;
      }
    });

    return newData as any;
  };

  const convertedData = data instanceof Object ? convert(data) : data;

  return convertedData;
};

export const convertCamelCaseToSnakeCase = (data: any) => {
  const toSnake = (x: string) => _.snakeCase(x);

  const convert = (item: any) => {
    let newData = item instanceof Array ? [] : {};
    _.forEach(item, (value, key) => {
      if (value instanceof Array) {
        newData[toSnake(key)] = [];
        _.forEach(value, v => {
          if (v instanceof Object) {
            newData[toSnake(key)].push(convert(v));
          } else {
            newData[toSnake(key)].push(v);
          }
        });
      } else if (value instanceof Object) {
        newData[toSnake(key)] = convert(value);
      } else {
        newData[toSnake(key)] = value;
      }
    });

    return newData as any;
  };

  const convertedData = data instanceof Object ? convert(data) : data;

  return convertedData;
};

export const isAllKeyTrue = (data: object) => {
  let flag = true;
  _.forEach(data, (v, k) => {
    if (v !== true) {
      flag = false;
    }
  });
  return flag;
};

export const setAllKeyFalse = (data: object) => {
  let result = {};
  _.forEach(data, (v, k) => {
    result[k] = false;
  });
  return result;
};

export const calculateDiscountPrice = (data: string, discountRate: number) => {
  // to remove $ from data
  const trimmed = _.trimStart(data, '$');
  const originalPrice = _.parseInt(trimmed, 10);
  const savedAmount = _.round((discountRate / 100) * originalPrice, 2);
  const discountedPrice = _.round(originalPrice - savedAmount, 2);

  return {
    savedAmount,
    discountedPrice,
  };
};

/**
 *
 * @param info
 * @param titles
 *
 * 将原始infoData根据需要的titles数组截取数据，如 (titles)['management.systems'，'ordering.system']
 *  则返回[{titles:'ordering.system',description:.....},{title:'management.systems',description:.....}]
 */
export const transferInfoIntoSelectedItems = (info: any, titles: any) => {
  const items = info.filter((item: any) => titles.includes(item.title));
  return items;
};

export const getValueFromMeta = (meta: any, key: any) => {
  const result = meta.filter((x: any) => x.key === key)[0]?.value;

  return result;
};

export const batchGetValueFromMeta = (meta: any, keysArrayNeedToFetch: string[]) => {
  let result = {};

  const keyValueObjArray = keysArrayNeedToFetch.map((item: any) => {
    const value = meta.filter((x: any) => x.key === item)[0]?.value;
    result[item] = value;
    return { key: item, value };
  });

  keyValueObjArray.map((x: any, idx: number) => {
    result[x.key] = x.value;
  });

  return result;
};

export const checkTwoValuesEqualsWithUnsignedTag: (valueOne: any, valueTwo: any) => boolean = (
  valueOne: any,
  valueTwo: any,
) => {
  let ret: boolean = false;

  const unsignedTags = [undefined, '', null, NaN, false];

  if (unsignedTags.includes(valueOne) && unsignedTags.includes(valueTwo)) {
    ret = true;
  }

  if (typeof valueOne === 'string' && typeof valueTwo === 'string' && valueOne === valueTwo) {
    ret = true;
  }

  return ret;
};

export const checkIsPassedListValid = (selectedList: number[], requireList: number) => {
  if (selectedList.length === requireList) {
    return true;
  }
  return false;
};

export const checkIsEmptyContent = (content: string) => {
  const removeBlankString = trim(content);

  if (isEmpty(removeBlankString)) {
    return true;
  }

  return false;
};

export const goBackToViewMenu = (viewMenuId = false) => {
  if (!viewMenuId) return router.push(`/ca/menus`);
  router.push(`/ca/menus/view/${viewMenuId}`);
};

export const getDateAndTimeFormat = (timeString: string, format = 'DD/MM/YYYY HH:mm') => {
  return moment(timeString).format(format);
};

export const getCorrectPhoneNoFormat = (
  customerPhone: string = '',
  businessCountryCode: string = 'AU',
) => {
  const addPlusToPhone = customerPhone.startsWith('+')
    ? customerPhone
    : `+${customerPhone.replace(/\s/g, '')}`; // 自动添加区号;

  const phoneNumber = parsePhoneNumber(addPlusToPhone);

  const businessSupportedCountry =
    isSupportedCountry(businessCountryCode) === true ? businessCountryCode : 'AU';

  const phoneNumberCountry = phoneNumber?.country;

  const newPhoneValue = phoneNumberCountry === businessSupportedCountry ? addPlusToPhone : '';

  return newPhoneValue;
};

export const getGst = (price: number, gstPercentage: number) => {
  const gst = (Number(price) / (1 + gstPercentage / 100)) * (gstPercentage / 100);

  return Number(gst);
};
