import { message } from 'antd';
import { Reducer } from 'redux';
import caSettings, { CaSettings } from '../../config/caSettings';
import { Subscription, Effect } from 'dva';
import {
  getCurrentCartStatus,
  exitCurrentRoom,
  creatNewRoom,
  getBusinessIdStatus,
  getTagList,
  postCreateNewIndividualOrder,
  getAllMenus,
  getOfferingTypes,
  postOrderDataToCurrentCart,
  updateOrderDataInCurrentCart,
  deleteOrderDataInCurrentCart,
  getBusinessConfigs,
  postNewRefNumber,
  postCancelStripeSession,
  getVerifyCaOrderHistoryCode,
  getBusinessAds,
  postOrderSecondValidation,
} from '@/services/ca';
import { getPageQuery, convertJsonToCamelCase, goBackToViewMenu } from '@/utils/utils';
import { validBusinessIdFormat } from '@/constants/ValidNumberFormat';
import router from 'umi/router';
import { CaCartType } from '@/constants/CaCartType';
import { CaViewerType } from '@/constants/CaViewerType';
import { ConnectState } from '@/models/connect';
import { getLoadedModuleStatus as metaGet } from '@/utils/metaModules';
import { setLoadedModuleStatus as caSet, reloadModuleStatus as caReload } from '@/utils/caModules';
import _ from 'lodash';
import { stringify, parse } from 'querystring';
import { metaTranslationOfCaGroups } from '@/constants/translationGroups';
import queryString from 'query-string';
import { delay } from '@/utils/polling';

export interface CaModelType {
  namespace: 'ca';
  state: CaSettings;
  effects: {
    fetchMeta: Effect;
    fetchBusinessIdStatus: Effect;
    fetchCurrentBusinessConfigs: Effect;
    fetchCurrentCartStatus: Effect;
    fetchCaAdvertise: Effect;

    //取资源
    fetchTagList: Effect;
    fetchOfferingTypeList: Effect;
    fetchAllMenus: Effect;

    //cart相关
    createNewRefNumber: Effect;
    creatNewGroup: Effect;
    createIndividalOrder: Effect;
    leaveCurrentRoom: Effect;
    addOrderDataToCurrentCart: Effect;
    updateOrderDataInCurrentCart: Effect;
    removeOrderDataInCurrentCart: Effect;
    postOrderSecondValidation: Effect;
    //order history相关
    confirmHistoryOrderView: Effect;

    //stripe相关
    deleteStripeSession: Effect;
  };
  reducers: {
    setIsValidBusinessId: Reducer<CaSettings>;
    saveCaAdvertise: Reducer<CaSettings>;

    setCaViewingId: Reducer<CaSettings>;
    setShowFirstWelcome: Reducer<CaSettings>;

    setViewerType: Reducer<CaSettings>;

    saveRefNumber: Reducer<CaSettings>;

    setCurrentCart: Reducer<CaSettings>;
    saveCurrentCartStatus: Reducer<CaSettings>;
    clearCurrentCart: Reducer<CaSettings>;
    setCurrentCartTotalItemNumber: Reducer<CaSettings>;
    saveCurrentCartTotalPrice: Reducer<CaSettings>;
    saveBaseCartTotalPriceFromRequst: Reducer<CaSettings>;
    saveCurrentCartAppliedCoupon: Reducer<CaSettings>;

    setCartCheckoutResult: Reducer<CaSettings>;

    setLoadedModule: Reducer<CaSettings>;
    setShowActioningMessage: Reducer<CaSettings>;

    saveTagList: Reducer<CaSettings>;
    saveOfferingTypeList: Reducer<CaSettings>;
    saveAllMenus: Reducer<CaSettings>;
    saveBusinessConfigs: Reducer<CaSettings>;

    //order history相关
    saveCaOrderHistoryViewToken: Reducer<CaSettings>;
    clearCaOrderHistoryViewToken: Reducer<CaSettings>;

    setCaModal: Reducer<CaSettings>;
    setIssueDishArray: Reducer<CaSettings>;
    setTips: Reducer<CaSettings>;

    setCurrentViewMenu: Reducer<CaSettings>;
  };
  subscriptions: {
    caInit: Subscription;
  };
}

const MetaModel: CaModelType = {
  namespace: 'ca',
  state: caSettings,
  effects: {
    *fetchMeta({ reload = false }, { put, all, select }) {
      //如果reload为true则先清空所有的trans
      if (reload) {
        yield put({
          //clear all flags for current translations
          type: 'meta/setLoadedModule',
          reload,
        });
      }
      /*
        1. transPkg
        2. cities
       */
      const { requiredLang, currentCart, metaModuleLoaded } = yield select(
        (state: ConnectState) => ({
          requiredLang: state.meta.viewingLang,
          currentCart: state.ca.currentCart,
          metaModuleLoaded: state.meta.metaModuleLoaded,
        }),
      );

      const transGroups = metaTranslationOfCaGroups; // TBD. minimal required transPkg for ca meta

      const payload = { currentCart, requiredLang, transGroups };

      let getActions = metaGet('ca', metaModuleLoaded, payload);

      if (getActions) {
        let actionArray: any = [];

        _.forEach(getActions, (value: any) => actionArray.push(put(value)));

        yield all(actionArray);
      }
    },
    *fetchBusinessIdStatus(_, { call, put }) {
      const response = yield call(getBusinessIdStatus);

      if (response.success) {
        //改状态
        yield put({
          type: 'setIsValidBusinessId',
          isValidBusinessId: true,
        });
      } else {
        router.replace('/od');
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchCaAdvertise(_, { call, put, select }) {
      const response = yield call(getBusinessAds, {});
      if (response.success) {
        const caAdvertise = convertJsonToCamelCase(response.result);
        //改状态
        yield put({
          type: 'saveCaAdvertise',
          caAdvertise,
        });

        // 改状态
        yield put({
          type: 'meta/setLoadedModule',
          key: 'caAdvertise',
          value: true,
        });
      } else {
        if (response.message) {
          // message.error(response.message);
        }
      }
    },
    *fetchCurrentBusinessConfigs(_, { call, put, select }) {
      const { viewingLang } = yield select((state: ConnectState) => ({
        viewingLang: state.meta.viewingLang,
      }));

      const response = yield call(getBusinessConfigs);
      const businessConfigs = convertJsonToCamelCase(response.result);

      if (response.success) {
        yield put({
          type: 'saveBusinessConfigs',
          businessConfigs,
        });

        //持久化slot时间
        if (businessConfigs.slot && localStorage) {
          localStorage.setItem('popsup-ca-slot-time', businessConfigs.slot);
        }

        if (
          businessConfigs.displayLang.length > 0 &&
          !businessConfigs.displayLang.includes(viewingLang)
        ) {
          //不包括的话要换掉这个lang
          yield put({
            type: 'meta/setViewingLang',
            viewingLang: businessConfigs.displayLang[0],
          });
          yield put({
            type: 'fetchMeta',
            reload: 'trans',
          });
        }
      } else {
        if (response.message) {
          // message.error(response.message);
        }
      }

      // 改状态
      yield put({
        type: 'meta/setLoadedModule',
        key: 'businessConfigs',
        value: true,
      });
    },
    *fetchCurrentCartStatus({ currentCart }, { call, put }) {
      const response = yield call(getCurrentCartStatus, { currentCart });

      if (response.success) {
        //这里要区分type TBD.
        yield put({
          type: 'saveCurrentCartStatus',
          status: response.result.status,
          pinCode: Number(response.result.pin_code),
          depositPercentage: Number(response.result.deposit_percentage),
          depositPrice: Number(response.result.deposit_price),
        });

        //如果成功了 则set小车车的badge数据
        yield put({
          type: 'setCurrentCartTotalItemNumber',
          currentCartTotalItemNumber: Number(response.result.cart_number),
        });

        //set小车车的总价
        yield put({
          type: 'saveCurrentCartTotalPrice',
          currentCartTotalPrice: Number(response.result.cart_price),
        });

        yield put({
          type: 'saveBaseCartTotalPriceFromRequst',
          baseCartTotalPriceFromRequst: Number(response.result.cart_price),
        });

        if (response.result.coupon) {
          //set有可能存在的coupon信息
          yield put({
            type: 'saveCurrentCartAppliedCoupon',
            currentCartAppliedCoupon: {
              ...convertJsonToCamelCase(response.result.coupon),
              originalTotalPrice: response.result.original_total_price,
              couponAppliedType: _.camelCase(response.result.coupon_applied_type),
              couponDiscount: response.result.coupon_discount,
            },
          });
        } else {
          yield put({
            type: 'saveCurrentCartAppliedCoupon',
          });
        }

        // message.success(response.message);
      } else {
        // 告诉用户购物车已经失效了
        if (response.message) {
          message.error(response.message, 6);
        }

        //如果没有的话代表这个购物车已经失效了
        //清空redux和localStorage里面的值
        yield put({
          type: 'clearCurrentCart',
        });
      }
      //改状态
      yield put({
        type: 'meta/setLoadedModule',
        key: 'currentCartStatus',
        value: true,
      });
    },
    *fetchAllMenus(_, { call, put }) {
      const response = yield call(getAllMenus);
      if (response.success) {
        const allMenus = convertJsonToCamelCase(response.result).map((x: any, key: number) => ({
          id: x.id,
          name: x.name,
          key,
        }));

        yield put({
          type: 'saveAllMenus',
          allMenus,
        });

        //改状态
        yield put({
          type: 'setLoadedModule',
          key: 'allMenus',
          value: true,
        });
      } else {
        //TBD.
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchOfferingTypeList(_, { call, put }) {
      const response = yield call(getOfferingTypes);
      if (response.success) {
        const data = convertJsonToCamelCase(response.result);
        const offeringTypeList = data.map((x: any) => ({ id: x.id, tagName: x.tagName }));

        yield put({
          type: 'saveOfferingTypeList',
          offeringTypeList,
        });

        // message.success(response.message);
        yield put({
          type: 'setLoadedModule',
          key: 'tag',
          subKey: 'offeringTypeList',
          value: true,
        });
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *fetchTagList(_, { call, put }) {
      const response = yield call(getTagList);
      if (response.success) {
        const tagList = convertJsonToCamelCase(response.result).map((x: any, key: number) => ({
          id: x.id,
          tagName: x.tagName,
          key,
        }));

        yield put({
          type: 'saveTagList',
          tagList,
        });

        //改状态
        yield put({
          type: 'setLoadedModule',
          key: 'tag',
          subKey: 'tagList',
          value: true,
        });
      } else {
        //TBD.
        if (response.message) {
          message.error(response.message);
        }
      }
    },

    *creatNewGroup(_, { call, put }) {
      yield put({
        type: 'setShowActioningMessage',
        showActioningMessage: true,
      });

      const response = yield call(creatNewRoom);
      if (response.success) {
        const currentCart = {
          cartId: response.result.order_id,
          cartType: response.result.type,
          participantId: response.result.current_participant_id,
        };

        yield put({
          type: 'setCurrentCart',
          currentCart,
        });

        //创建新group之后先把room状态换掉 - meta状态机
        yield put({
          type: 'fetchMeta',
          reload: 'room-status',
        });

        //再把cart-fresh了 - ca状态机
        yield put({
          type: 'setLoadedModule',
          reload: 'caCart-fresh',
        });

        yield put({
          type: 'setCaModal',
          caModal: {
            show: true,
            content: 'pinCode',
          },
        });

        router.goBack();

        message.success(response.message);
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
      yield put({
        type: 'setShowActioningMessage',
        showActioningMessage: false,
      });
    },
    *leaveCurrentRoom(_, { call, put, select }) {
      yield put({
        type: 'setShowActioningMessage',
        showActioningMessage: true,
      });

      const { cartId, participantId } = yield select((state: ConnectState) => ({
        cartId: state.ca.currentCart ? state.ca.currentCart.cartId : false,
        participantId: state.ca.currentCart ? state.ca.currentCart.participantId : false,
      }));

      const response = yield call(exitCurrentRoom, { cartId, participantId });

      if (response.success) {
        //清空redux和localStorage里面的值
        yield put({
          type: 'clearCurrentCart',
        });

        //同时把状态机里面的cartInfo也清空 表示需要下次重新load
        yield put({
          type: 'setLoadedModule',
          key: 'caCart',
          subKey: 'self',
          value: false,
        });

        // yield put({
        //   type: 'fetchMeta',
        //   reload: 'room-status',
        // });

        router.push('/ca/home');

        message.success(response.message);
      } else {
        //TBD.
        if (response.message) {
          message.error(response.message);
        }
      }

      yield put({
        type: 'setShowActioningMessage',
        showActioningMessage: false,
      });
    },

    *createIndividalOrder({ orderData = false }, { select, call, put }) {
      if (orderData) {
        //带了菜来开的 TBD.
        yield put({
          type: 'setShowActioningMessage',
          showActioningMessage: true,
        });
      }

      const response = yield call(postCreateNewIndividualOrder, { orderData });
      if (response.success) {
        const currentCart = {
          cartId: response.result.order_id,
          cartType: response.result.type,
        };

        yield put({
          type: 'setCurrentCart',
          currentCart,
        });

        yield put({
          type: 'fetchMeta',
          reload: 'room-status',
        });

        const { businessConfigs, currentViewMenu } = yield select((state: ConnectState) => ({
          businessConfigs: state.ca.businessConfigs,
          currentViewMenu: state.ca.currentViewMenu,
        }));

        //这里决定加dish成功以后往哪里跳转: 1 - goBack , 2 - cart summary
        const redirectRoute = businessConfigs.redirectAfterAddDish;
        if (redirectRoute === 'cart') {
          router.push('/ca/cart');
        } else if (redirectRoute === 'back') {
          goBackToViewMenu(currentViewMenu);
        }

        message.success(response.message);

        // 以前的逻辑暂时启用
        // // if (orderData) {
        //   //有带菜开单的话 退出去
        // //   router.goBack();
        // // }

        //把状态机里面的caDish的数据也清空 表示需要下次重新load
        yield put({
          type: 'setLoadedModule',
          key: 'caDish',
          subKey: 'self',
          value: false,
        });
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
      if (orderData) {
        yield put({
          type: 'setShowActioningMessage',
          showActioningMessage: false,
        });
      }
    },
    *addOrderDataToCurrentCart({ orderData = false }, { call, put, select }) {
      // orderData必须包含 type = deal ｜ dish，id,  quantity 和 notes可以为空
      // 当type 为dish 会包括额外pairedList
      // 当type 为deal 会包括额外redeemCode
      // dish和deal的orderData最多有5个值

      const { currentCart, businessConfigs, currentViewMenu } = yield select(
        (state: ConnectState) => ({
          currentCart: state.ca.currentCart,
          businessConfigs: state.ca.businessConfigs,
          currentViewMenu: state.ca.currentViewMenu,
        }),
      );

      const response = yield call(postOrderDataToCurrentCart, { orderData, currentCart });

      if (response.success) {
        // 第一步 如果成功了 则set小车车的badge数据
        yield put({
          //把这个currentCart的totalNumber置零
          type: 'setCurrentCartTotalItemNumber',
          currentCartTotalItemNumber: Number(response.result.cart_number),
        });

        //第二步 重置caCart的状态机
        //把状态机里面的cartInfo也清空 表示需要下次重新load
        yield put({
          type: 'setLoadedModule',
          key: 'caCart',
          subKey: 'self',
          value: false,
        });

        //这里决定加dish成功以后往哪里跳转: 1 - goBack , 2 - cart summary
        const redirectRoute = businessConfigs.redirectAfterAddDish;
        if (redirectRoute === 'cart') {
          router.push('/ca/cart');
        } else {
          goBackToViewMenu(currentViewMenu);
        }

        //第三步 重置caDish的状态机
        //把状态机里面的caDish的数据也清空 表示需要下次重新load
        yield put({
          type: 'setLoadedModule',
          key: 'caDish',
          subKey: 'self',
          value: false,
        });
        message.success(response.message);
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *updateOrderDataInCurrentCart({ currentOrderSummary }, { call, put, select }) {
      const { viewerType, currentCart, businessConfigs } = yield select((state: ConnectState) => ({
        currentCart: state.ca.currentCart,
        viewerType: state.ca.viewerType,
        businessConfigs: state.ca.businessConfigs,
      }));

      const { enableInventoryFeature = false } = businessConfigs;

      const response = yield call(updateOrderDataInCurrentCart, {
        viewerType,
        currentOrderSummary,
        currentCart,
      });

      if (response.success) {
        yield put({
          type: 'caCart/fetchMeta',
          reload: 'caCart-fresh',
        });
        message.success(response.message);
      } else {
        message.warning(response.message);
        //如果订单错误更新一下订单，并message 错误内容
        yield put({
          type: 'caCart/fetchMeta',
          reload: 'caCart-fresh',
        });

        if (response.message) {
          message.error(response.message);
        }
      }
      // 不管成功还是失败如果开了enableInventoryFeature每次更新都确认一遍菜品状态
      if (enableInventoryFeature) {
        yield put({
          type: 'postOrderSecondValidation',
        });
      }
    },
    *postOrderSecondValidation({}, { call, put, select }) {
      const { currentCart } = yield select((state: ConnectState) => ({
        currentCart: state.ca.currentCart,
        viewerType: state.ca.viewerType,
      }));

      const response = yield call(postOrderSecondValidation, {
        currentCart,
      });

      const result = convertJsonToCamelCase(response.result);

      // 没成功的话保存那些错误的dishes
      if (!response.success) {
        yield put({ type: 'setIssueDishArray', issueDishArray: result });

        if (response.message) {
          message.error(response.message);
        }
        return false;
      }

      // 成功的话把issue dish array设置为false
      yield put({ type: 'setIssueDishArray', issueDishArray: false });

      return true;
    },

    *removeOrderDataInCurrentCart(_, { call, put, select }) {
      const { currentCart, removeItem } = yield select((state: ConnectState) => ({
        currentCart: state.ca.currentCart,
        removeItem: state.caCart.selectedRemoveItem,
      }));

      const response = yield call(deleteOrderDataInCurrentCart, { removeItem, currentCart });

      if (response.success) {
        yield put({
          type: 'caCart/fetchMeta',
          reload: 'caCart-fresh',
        });
        message.success(response.message);
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *createNewRefNumber(_, { call, put, select }) {
      const { currentCart } = yield select((state: ConnectState) => ({
        currentCart: state.ca.currentCart,
      }));

      const response = yield call(postNewRefNumber, { currentCart });
      if (response.success) {
        yield put({
          type: 'saveRefNumber',
          refNumber: String(response.result),
        });
        if (response.message) {
          message.success(response.message);
        }
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *confirmHistoryOrderView({ payload }, { call, put, select }) {
      //这里触发后端的验证 -- 验证sms code
      const response = yield call(getVerifyCaOrderHistoryCode, { payload });
      if (response.success) {
        if (response.message) {
          //把这个result保存下来
          yield put({
            type: 'saveCaOrderHistoryViewToken',
            caOrderHistoryViewToken: response.result,
          });
          yield put({
            type: 'setCaModal',
            caModal: {
              show: false,
            },
          });

          router.push('/ca/previous-order');
        }
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
    *deleteStripeSession(_, { call, put, select }) {
      const { currentCart, cartCheckoutResult } = yield select((state: ConnectState) => ({
        currentCart: state.ca.currentCart,
        cartCheckoutResult: state.ca.cartCheckoutResult,
      }));

      const response = yield call(postCancelStripeSession, { currentCart, cartCheckoutResult });
      if (response.success) {
        if (response.message) {
          message.success(response.message);
        }
      } else {
        if (response.message) {
          message.error(response.message);
        }
      }
    },
  },
  reducers: {
    setIsValidBusinessId(state = caSettings, { isValidBusinessId }) {
      return {
        ...state,
        isValidBusinessId,
      };
    },
    saveCaAdvertise(state = caSettings, { caAdvertise }) {
      return {
        ...state,
        caAdvertise,
      };
    },
    setCaViewingId(state = caSettings, { viewingBusinessId }) {
      if (localStorage) {
        localStorage.setItem('popsup-ca-viewing-id', viewingBusinessId);
      }
      return {
        ...state,
        viewingBusinessId,
      };
    },
    setShowFirstWelcome(state = caSettings, { showFirstWelcome }) {
      return {
        ...state,
        showFirstWelcome,
      };
    },
    setViewerType(state = caSettings, { viewerType }) {
      return {
        ...state,
        viewerType,
      };
    },
    setCurrentCart(state = caSettings, { currentCart, init = false }) {
      let entity: any;
      entity = {
        cart_id: currentCart.cartId,
        cart_type: currentCart.cartType,
      };
      if (currentCart.cartType === 'group' && currentCart.participantId) {
        entity = { ...entity, participant_id: currentCart.participantId };
      }
      if (localStorage && !init) {
        //如果不是init的话 将其持久化
        localStorage.setItem('popsup-ca-cart-info', JSON.stringify(entity));
      }
      return {
        ...state,
        currentCart,
      };
    },
    clearCurrentCart(state = caSettings) {
      if (localStorage && localStorage.getItem('popsup-ca-cart-info')) {
        localStorage.removeItem('popsup-ca-cart-info');
      }
      return {
        ...state,
        currentCartStatus: false,
        currentCart: false,
        currentCartTotalItemNumber: 0,
      };
    },
    saveCurrentCartStatus(
      state = caSettings,
      { status, pinCode, depositPercentage, depositPrice },
    ) {
      return {
        ...state,
        currentCartStatus: {
          status,
          pinCode,
          depositPercentage,
          depositPrice,
        },
      };
    },

    saveOfferingTypeList(state = caSettings, { offeringTypeList }) {
      return {
        ...state,
        offeringTypeList,
      };
    },
    saveTagList(state = caSettings, { tagList }) {
      return {
        ...state,
        tagList,
      };
    },
    saveAllMenus(state = caSettings, { allMenus }) {
      return {
        ...state,
        allMenus,
      };
    },

    setLoadedModule(state = caSettings, { reload = false, key, subKey, value }) {
      let currentLoadedModule = state.caModuleLoaded;
      if (reload) {
        currentLoadedModule = caReload(reload, currentLoadedModule);
      } else {
        if (key && subKey) {
          currentLoadedModule.api[key][subKey] = value;
        } else if (key) {
          currentLoadedModule.api[key] = value;
        }
      }
      const caModuleLoaded = caSet(currentLoadedModule);
      return {
        ...state,
        caModuleLoaded,
      };
    },
    saveCaOrderHistoryViewToken(state = caSettings, { caOrderHistoryViewToken, init = false }) {
      //只有当init为false --也就是由程序触发改变的时候
      //将这个改变set到localStorage里面
      if (localStorage && !init) {
        //如果不是init的话 将其持久化
        localStorage.setItem(
          'popsup-ca-order-history-view-token',
          JSON.stringify(caOrderHistoryViewToken),
        );
      }
      return {
        ...state,
        caOrderHistoryViewToken,
      };
    },
    clearCaOrderHistoryViewToken(state = caSettings) {
      localStorage.removeItem('popsup-ca-order-history-view-token');
      return {
        ...state,
        caOrderHistoryViewToken: false,
      };
    },
    setShowActioningMessage(state = caSettings, { showActioningMessage }) {
      return {
        ...state,
        showActioningMessage,
      };
    },
    setCurrentCartTotalItemNumber(state = caSettings, { currentCartTotalItemNumber = 0 }) {
      return {
        ...state,
        currentCartTotalItemNumber,
      };
    },
    saveCurrentCartTotalPrice(state = caSettings, { currentCartTotalPrice }) {
      return {
        ...state,
        currentCartTotalPrice,
      };
    },
    saveBaseCartTotalPriceFromRequst(state = caSettings, { baseCartTotalPriceFromRequst }) {
      return {
        ...state,
        baseCartTotalPriceFromRequst,
      };
    },
    saveBusinessConfigs(state = caSettings, { businessConfigs }) {
      return {
        ...state,
        businessConfigs,
      };
    },
    saveCurrentCartAppliedCoupon(state = caSettings, { currentCartAppliedCoupon = false }) {
      return {
        ...state,
        currentCartAppliedCoupon,
      };
    },
    setCartCheckoutResult(state = caSettings, { cartCheckoutResult }) {
      return {
        ...state,
        cartCheckoutResult,
      };
    },
    saveRefNumber(state = caSettings, { refNumber = '' }) {
      return {
        ...state,
        refNumber,
      };
    },
    setCaModal(state = caSettings, { caModal }) {
      return {
        ...state,
        caModal,
      };
    },

    setIssueDishArray(state = caSettings, { issueDishArray }) {
      return {
        ...state,
        issueDishArray,
      };
    },
    setTips(state = caSettings, { tips = false }) {
      return {
        ...state,
        tips,
      };
    },

    setCurrentViewMenu(state = caSettings, { currentViewMenu = false }) {
      return {
        ...state,
        currentViewMenu,
      };
    },
  },
  subscriptions: {
    caInit({
      dispatch,
      history: {
        location: { pathname, search },
      },
    }): any {
      // console.log(`[MODEL] pathname: ${pathname}` + ' CA model - init - set up ca from url params');
      // console.log(`search: ${search}`);

      const {
        ca_id,
        ca_viewer_type,
        cart_id,
        cart_type,
        participant_id = false,
        cart_checkout_status,
        cart_checkout_session_id = false,
        ...queryParams
      } = queryString.parse(search) as {
        ca_id: string;
        ca_viewer_type: string;
        cart_id: string;
        cart_type: string;
        participant_id: string;
        cart_checkout_status: string;
        cart_checkout_session_id: string;
        queryParams: any;
      };

      //============处理caId (local | query)==============//
      let caId = ca_id && validBusinessIdFormat.test(ca_id) ? Number(ca_id) : false;

      if (!caId) {
        // if no city passed from url, try to read from the local storage
        const localCaId =
          localStorage && localStorage.getItem('popsup-ca-viewing-id')
            ? localStorage.getItem('popsup-ca-viewing-id')
            : false;
        caId = localCaId && validBusinessIdFormat.test(localCaId) ? Number(localCaId) : false; //TBD.
      }

      if (caId) {
        //set viewingBusinessId
        dispatch({
          type: 'setCaViewingId',
          viewingBusinessId: caId,
        });
      }
      //============处理caId - End ==============//

      //============处理caViewerType (query)==============//

      const caViewerType =
        ca_viewer_type && CaViewerType.includes(ca_viewer_type) ? ca_viewer_type : false;

      if (caViewerType) {
        //set ca-viwer-type
        dispatch({
          type: 'setViewerType',
          viewerType: caViewerType,
        });
      }
      //============处理caViewerType - End ==============//

      //============处理购物车信息 - (local | query)==============//

      let cartId = cart_id ? Number(cart_id) : false;
      let cartType = cart_type && CaCartType.includes(cart_type) ? cart_type : false;
      let participantId: any = cart_type === 'group' && participant_id ? participant_id : false;

      //只要两个值有一个为false 则去localStorage里面取
      if (!cartId || !cartType) {
        //local - 购物车
        const localCartInfo =
          localStorage &&
          localStorage.getItem('popsup-ca-cart-info') &&
          JSON.parse(localStorage.getItem('popsup-ca-cart-info') as string)
            ? JSON.parse(localStorage.getItem('popsup-ca-cart-info') as string)
            : false;

        const cartInfo =
          localCartInfo &&
          Number(localCartInfo.cart_id) &&
          CaCartType.includes(localCartInfo.cart_type)
            ? localCartInfo
            : false;

        cartId = cartInfo.cart_id;
        cartType = cartInfo.cart_type;
        participantId = cartInfo.participant_id;
      }

      if (cartId && cartType) {
        dispatch({
          type: 'setCurrentCart',
          currentCart: {
            cartId,
            cartType,
            participantId,
          },
          init: true,
        });
      }
      //============处理购物车信息 - End ==============//

      //=============== 处理stripe付款后的请求 checkout_result (query) - Start ===============//

      //** 校验checkout result是否为有效参数 *///
      const validCartCheckoutResults = ['success', 'abort'];

      if (validCartCheckoutResults.includes(cart_checkout_status)) {
        dispatch({
          type: 'setCartCheckoutResult',
          cartCheckoutResult: { status: cart_checkout_status, sessionId: cart_checkout_session_id },
        });
      }
      //=============== 处理stripe付款后的请求 checkout_result (query) - End ===============//

      //=============== 处理caOrderHistoryViewToken (local) - Start ===============//
      const caOrderHistoryViewToken =
        localStorage &&
        localStorage.getItem('popsup-ca-order-history-view-token') &&
        JSON.parse(localStorage.getItem('popsup-ca-order-history-view-token') as string)
          ? JSON.parse(localStorage.getItem('popsup-ca-order-history-view-token') as string)
          : false;

      if (caOrderHistoryViewToken) {
        dispatch({
          type: 'saveCaOrderHistoryViewToken',
          caOrderHistoryViewToken,
          init: true,
        });
      }
      //=============== 处理caOrderHistoryViewToken (local) - End ===============//

      router.replace({
        pathname: pathname === '/' ? '/od' : pathname,
        search: stringify({ ...queryParams } as any),
      });
    },
  },
};
export default MetaModel;
